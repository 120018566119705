<template>
  <div class="page-container">
    <!-- Features Menu -->
    <CreditScoringFeaturesMenu />

    <!-- Main Upload Card -->
    <v-card class="card-container elevation-2">
      <v-card-title class="headline d-flex justify-space-between align-center" style="color: #172b4d">
        <span>Manage Loan Data Uploads</span>
        <v-btn
          color="primary"
          @click="showNewUploadModal = true"
          v-if="hasPrivilege('Upload Data')"
        >
          <v-icon left>cloud_upload</v-icon>
          Upload New File
        </v-btn>
      </v-card-title>
      
      <v-card-text>
        <v-alert
          v-if="!hasPrivilege('Upload Data')"
          type="warning"
          dense
          outlined
          class="mb-4"
        >
          You have view-only access. The "Upload Data" privilege is required to upload or modify files.
        </v-alert>
      <error-dialog
        v-model="alert.show"
        :title="alert.title"
        :message="alert.message"
        :details="alert.details"
        :type="alert.type"
        :suggestion="alert.suggestion"
        :show-retry-button="alert.showRetryButton"
        :show-action-button="alert.showActionButton"
        :action-button-text="alert.actionButtonText"
        :action-button-color="alert.actionButtonColor"
        @close="alert.show = false"
        @retry="handleAlertRetry"
        @action="handleAlertAction"
      />

        <v-form ref="form" v-model="valid">
          <v-expansion-panel
            v-model="activePanel"
          >
            <template v-for="year in Object.keys(files).sort().reverse()">
              <v-expansion-panel-content :key="year">
                <div slot="header">
                  <h3>{{year}} ({{files[year].length}} files)</h3>
                </div>
                <v-card
                  v-for="file in files[year]"
                  :key="`${file._id}-${file.upload_date}`"
                  class="card-spacer"
                >
                  <v-card-title class="py-2">
                    {{file.month}} : {{file.file_name}}
                    <v-spacer></v-spacer>
                    <v-btn 
                      icon 
                      color="primary" 
                      @click="replaceFile(file)"
                      v-if="hasPrivilege('Upload Data')"
                    >
                      <v-icon>sync</v-icon>
                    </v-btn>
                    <v-btn 
                      icon 
                      color="primary" 
                      @click="confirmDelete(file)"
                      v-if="hasPrivilege('Upload Data')"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-container fluid class="pa-3">
                    <v-layout row wrap>
                      <v-flex xs12 md2 class="py-1">
                        <span class="font-weight-medium">Total Loans:</span>
                        <span class="ml-1">{{file.total_loans}}</span>
                      </v-flex>
                      <v-flex xs12 md3 class="py-1">
                        <span class="font-weight-medium">Total Amount:</span>
                        <span class="ml-1">{{asMoney(file.total_loan_amount)}}</span>
                      </v-flex>
                      <v-flex xs12 md3 class="py-1">
                        <span class="font-weight-medium">Total Outstanding Balance:</span>
                        <span class="ml-1">{{asMoney(file.total_loan_balance)}}</span>
                      </v-flex>
                      <v-flex xs12 md2 class="py-1">
                        <span class="font-weight-medium">Uploaded by:</span>
                        <span class="ml-1">{{file.upload_user}}</span>
                      </v-flex>
                      <v-flex xs12 md2 class="py-1">
                        <span class="font-weight-medium">Uploaded on:</span>
                        <span class="ml-1">{{file.upload_date}}</span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-expansion-panel-content>
            </template>
          </v-expansion-panel>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Delete Confirmation Dialog -->
    <v-dialog
      v-model="showDeleteModal"
      width="50%"
      v-if="deleteFile"
    >
      <v-card>
        <v-card-title
          class="headline red white--text"
          primary-title
        >
          Warning, you are about to delete "{{deleteFile.file_name}}"
        </v-card-title>

        <v-card-text class="pt-4">
          This action is irreversible, proceed?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDeleteModal = false">
            Cancel
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteUploadedFile(deleteFile._id)"
          >
            Confirm Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New file upload component -->
    <file-upload 
      :visible.sync="showNewUploadModal"
      @upload-success="handleUploadSuccess"
      @upload-error="handleUploadError"
      @report-generated="handleReportGenerated"
      @view-existing-report="viewExistingReport"
    ></file-upload>

    <!-- Replace file upload component -->
    <replace-file-upload 
      :visible.sync="showReplaceModal"
      :existingFile="fileToReplace"
      @replace-success="handleReplaceSuccess"
      @replace-error="handleReplaceError"
      @report-generated="handleReportGenerated"
      @view-existing-report="viewExistingReport"
    ></replace-file-upload>

    <data-loading :visible="dataLoading"></data-loading>
  </div>
</template>

<script>
import creditScoringService from "@/services/creditScoringService";
import newFileUpload from "./NewFileUploadComponent.vue";
import replaceFileUpload from "./ReplaceFileUploadComponent.vue";
import CreditScoringFeaturesMenu from '@/components/CreditScoringFeaturesMenu.vue';
import ErrorDialog  from '@/components/ErrorDialogComponent.vue';
import { parseApiError, getStatusBasedErrorMessage } from '@/common/errorHandlingUtils';
import moment from "moment-timezone";
import accessControlMixin from '@/mixins/accessControlMixin';

export default {
  name: 'CreditScoreUpload',
  mixins: [accessControlMixin],
  
  components: {
    'file-upload': newFileUpload,
    'replace-file-upload': replaceFileUpload,
    CreditScoringFeaturesMenu,
    ErrorDialog 
  },
  
  data: () => ({
    valid: false,
    loading: false,
    dataLoading: false,
    showNewUploadModal: false,
    showDeleteModal: false,
    showReplaceModal: false,
    deleteFile: null,
    files: {},
    activePanel: null,
    fileToReplace: {},
    alert: {
      show: false,
      title: 'Error',
      type: 'error',
      message: '',
      details: '',
      suggestion: '',
      showRetryButton: false,
      showActionButton: false,
      actionButtonText: 'Action',
      actionButtonColor: 'primary',
      retryCallback: null,
      actionCallback: null
    }
  }),

  async mounted() {
    if (!this.hasPrivilege('Upload Data')) {
      this.$store.dispatch('showSnackbar', {
        text: 'You are in view-only mode. The "Upload Data" privilege is required to upload or modify files.',
        color: 'info'
      });
    }
    this.init();
  },

  methods: {
    async init() {
      this.files = {};
      this.dataLoading = true;
      try {
        await this.loadUploadedFiles();
      } catch (e) {
        console.error("Exception while initializing file uploads", e);
        this.showAlert("Failed to initialize file uploads", 'error', e.message);
      } finally {
        this.dataLoading = false;
      }
    },

    async loadUploadedFiles() {
      try {
        const result = await creditScoringService.fetchUploads();

        if (!result.result) {
          const parsedError = parseApiError(result.error, "Failed to retrieve uploaded files!");
          this.showAlert(parsedError.message, 'error', parsedError.details);
          return;
        }

        // Clear existing files first to prevent duplicates
        this.files = {};

        // Group files by year
        result.value.forEach(file => {
          if (!this.files[file.year]) {
            this.files[file.year] = [];
          }
          
          // Add timestamp to object to ensure Vue treats it as a new object
          file._timestamp = Date.now();
          
          // Check for duplicates before adding
          const existingFileIndex = this.files[file.year].findIndex(f => f._id === file._id);
          if (existingFileIndex >= 0) {
            // Replace existing file with updated version
            this.files[file.year].splice(existingFileIndex, 1, file);
          } else {
            // Add new file
            this.files[file.year].push(file);
          }
        });

        // Sort by month in reverse order
        Object.keys(this.files).forEach(year => {
          this.files[year] = [...this.files[year]]; 
          this.files[year] = this.files[year].sort((a, b) => 
            moment(`${year}${b.month}25`, 'YYYYMMMMdd')
              .diff(moment(`${year}${a.month}25`, 'YYYYMMMMdd'))
          );
        });

        setTimeout(() => {
          this.activePanel = this.activePanel !== undefined && this.activePanel !== null ? this.activePanel : 0;
        }, 50);
      } catch (e) {
        console.error("Failed to retrieve files", e);
        const parsedError = parseApiError(e, "Failed to retrieve uploaded files!");
        this.showAlert(parsedError.message, 'error', parsedError.details);
      }
    },

    confirmDelete(file) {
      if (!this.hasPrivilege('Upload Data')) {
        this.showUnauthorizedError('Upload Data');
        return;
      }
      this.deleteFile = file;
      this.showDeleteModal = true;
    },

    async deleteUploadedFile(fileId) {
      if (!this.hasPrivilege('Upload Data')) {
        this.showUnauthorizedError('Upload Data');
        return;
      }
      this.dataLoading = true;
      try {
        const result = await creditScoringService.deleteFile(fileId);
        if (result.result) {
          this.showAlert("File deleted successfully!", 'success');
        } else {
          const parsedError = parseApiError(result.error, "Failed to delete file!");
          this.showAlert(parsedError.message, 'error', parsedError.details);
        }
      } catch (e) {
        console.error("Failed to delete file", e);
        const parsedError = parseApiError(e, "Failed to delete file!");
        this.showAlert(parsedError.message, 'error', parsedError.details);
      } finally {
        this.dataLoading = false;
        this.init();
        this.showDeleteModal = false;
      }
    },

    handleUploadSuccess(uploadedFile) {
      this.showAlert(`File "${uploadedFile.file_name}" uploaded successfully!`, 'success');
      this.init();
    },

    handleUploadError(error) {
      console.error("Upload error:", error);
      const parsedError = parseApiError(error, "Failed to upload file. Please try again.");
      if (parsedError.status) {
        const statusError = getStatusBasedErrorMessage(parsedError.status, (error.response && error.response.data));
        this.showAlert(statusError.message, 'error', statusError.details);
      } else {
        this.showAlert(parsedError.message, 'error', parsedError.details);
      }
    },

    handleReplaceSuccess(replacedFile) {
      this.showAlert(`File "${replacedFile.file_name}" replaced successfully!`, 'success');
      setTimeout(() => {
        this.init();
      }, 500);
    },

    handleReplaceError(error) {
      console.error("Replace error:", error);
      const parsedError = parseApiError(error, "Failed to replace file. Please try again.");
      this.showAlert(parsedError.message, 'error', parsedError.details);
    },

    replaceFile(file) {
      if (!this.hasPrivilege('Upload Data')) {
        this.showUnauthorizedError('Upload Data');
        return;
      }
      this.fileToReplace = { ...file, _timestamp: Date.now() };
      this.showReplaceModal = true;
    },

    showUnauthorizedError(privilegeName) {
      this.showAlert(
        `Access Denied: You don't have the required "${privilegeName}" privilege`,
        'error',
        'This action requires specific permissions that are not assigned to your account.',
        {
          suggestion: 'Please contact your administrator if you need access to this feature.'
        }
      );
    },

    showAlert(message, type = 'info', details = '', options = {}) {
      this.alert = {
        show: true,
        title: options.title || (type === 'error' ? 'Error' : type === 'warning' ? 'Warning' : 'Information'),
        type,
        message,
        details,
        suggestion: options.suggestion || '',
        showRetryButton: !!options.showRetryButton,
        showActionButton: !!options.showActionButton,
        actionButtonText: options.actionButtonText || 'Action',
        actionButtonColor: options.actionButtonColor || 'primary',
        retryCallback: options.retryCallback || null,
        actionCallback: options.actionCallback || null
      };
    },

    handleAlertRetry() {
      if (typeof this.alert.retryCallback === 'function') {
        this.alert.retryCallback();
      }
      this.alert.show = false;
    },

    handleAlertAction() {
      if (typeof this.alert.actionCallback === 'function') {
        this.alert.actionCallback();
      }
      this.alert.show = false;
    },

    handleReportGenerated(reportData) {
      this.showAlert(`Credit score report generated successfully for ${reportData.loans.length} loans`, 'success');
      
      // Close the upload dialog
      this.showNewUploadModal = false;
      this.showReplaceModal = false;
      
      // Navigate to the view-reports page with reportId as query parameter
      this.$router.push({
        path: '/app/credit-scoring/view-reports',
        query: { reportId: reportData.reportId }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation error:', err);
        }
      });
    },

    viewExistingReport(reportId) {
      // Navigate to the existing report
      this.$router.push({
        path: '/app/credit-scoring/view-reports',
        query: { reportId: reportId }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation error:', err);
        }
      });
    },

    // Format amount as currency
    asMoney(amount) {
      if (amount === undefined || amount === null) return '$0.00';
      
      // Convert from various formats
      let num = amount;
      if (typeof amount === 'string') {
        num = parseFloat(amount);
      } else if (typeof amount === 'object' && amount.$numberDecimal) {
        num = parseFloat(amount.$numberDecimal);
      }
      
      if (isNaN(num)) return '$0.00';
      
      // Format as currency
      return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(num);
    }
  },

  watch: {
    showNewUploadModal(newValue) {
      if (!newValue) {
        this.init();
      }
    },
    showReplaceModal(newValue) {
      if (!newValue) {
        this.init();
      }
    }
  }
}
</script>

<style scoped>
.page-container {
  padding: 20px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.card-container {
  width: 100%;
  margin-bottom: 20px;
}

.card-spacer {
  margin-bottom: 15px;
}

.headline {
  font-size: 20px !important;
  font-weight: 500;
}

.font-weight-medium {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.v-card__title {
  padding: 16px 24px !important;
}

.v-container {
  padding: 12px 16px !important;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .v-flex {
    margin-bottom: 8px;
  }
}
</style>